import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { theme } from 'theme';
import { PageContextType } from 'types';

import Layout from 'components/templates/layout';
import HeadTitleDescriptionImage, {
  BannerPrimaryType,
} from 'components/organisms/head-title-description-image/head-title-description-image';
import ManagerSection, { ManagerSectionType } from 'components/organisms/manager-section';
import OurMissionSection, {
  TextBannerWithImageType,
} from 'components/organisms/our-mission-section';
import RecruitmentProcessSteps, {
  RecruitmentProcessStepsType,
} from 'components/organisms/recruitment-process-steps';
import TextBannerCardSection, {
  TextBannerCardType,
} from 'components/organisms/text-banner-card-section';
import BannerCard from 'components/molecules/banner-card';
import CompanyTextContainer from 'components/molecules/company-text-container';
import LetsTalkBanner, { LetsTalkBannerType } from 'components/molecules/lets-talk-banner';
import Breadcrumb from 'components/atoms/breadcrumb/breadcrumb';

type DataProps = {
  commonComponents: {
    commonComponentsData: {
      letsTalkBanner: LetsTalkBannerType;
    };
  };
  offersMediaService: {
    template: {
      offersMediaServiceData: {
        bannerQuaternary: BannerPrimaryType;
        employerBrandingDescription: TextBannerCardType;
        mediaServiceWhatIs: TextBannerWithImageType;
        mediaServiceWhen: TextBannerWithImageType;
        advantagesSection: TextBannerCardType;
        recruitmentProcess: RecruitmentProcessStepsType;
        employerBrandingTeam: ManagerSectionType;
        otherServicesBlock: TextBannerCardType;
      };
    };
  };
};

export const OffersMediaServicePage = ({
  pageContext,
  data: {
    offersMediaService: {
      template: {
        offersMediaServiceData: {
          bannerQuaternary: { heading, image, text },
          employerBrandingDescription,
          mediaServiceWhatIs,
          mediaServiceWhen,
          advantagesSection,
          employerBrandingTeam,
          recruitmentProcess,
          otherServicesBlock,
        },
      },
    },
    commonComponents: {
      commonComponentsData: { letsTalkBanner },
    },
  },
}: PageProps<DataProps, PageContextType>) => (
  <Layout {...{ pageContext }}>
    <HeadTitleDescriptionImage
      titleTop={heading}
      subtitleTop={text}
      showDots
      positionTitle="left"
      image={image}
    >
      <Breadcrumb {...{ pageContext }} />
    </HeadTitleDescriptionImage>
    <TextBannerCardSection
      variantHeading="title9"
      {...employerBrandingDescription}
      margin="72px auto 116px"
      padding="0"
      mobilePadding="36px 16px 36px"
      textColumn
    />

    <OurMissionSection firstSection={mediaServiceWhatIs} secondSection={mediaServiceWhen} />
    <TextBannerCardSection
      mobilePadding="36px 16px 36px"
      {...advantagesSection}
      bgColor={theme.colors.grayscaleGray0}
    >
      {advantagesSection.cardList.map((item) => (
        <BannerCard
          {...item}
          bgColor="white"
          padding={theme.gap.md}
          mobilePadding={theme.gap.sm}
          key={item.text}
          shadow
        />
      ))}
    </TextBannerCardSection>
    <CompanyTextContainer>
      <RecruitmentProcessSteps mobilePadding="20px 0 36px" {...recruitmentProcess} />
    </CompanyTextContainer>
    <ManagerSection {...employerBrandingTeam} />
    <LetsTalkBanner {...letsTalkBanner} margin={`${theme.gap.xl} auto ${theme.gap.xl}`} />
    <TextBannerCardSection
      variantHeading="title7"
      mobilePadding={`${theme.gap.mdl} ${theme.gap.sm}`}
      padding={`0 0 ${theme.gap.xl}`}
      {...otherServicesBlock}
      textColumn
    >
      {otherServicesBlock && otherServicesBlock.cardList
        ? otherServicesBlock.cardList.map((item) => (
            <BannerCard maxWidth="350px" {...item} key={item.text} />
          ))
        : null}
    </TextBannerCardSection>
  </Layout>
);

export const query = graphql`
  query OffersMediaService($id: String!, $commonComponentsSlug: String!) {
    offersMediaService: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_OffersMediaService {
          offersMediaServiceData {
            advantagesSection {
              heading
              subTitle
              text
              cardList: advantagesList {
                text
                heading
                icon {
                  name
                }
              }
            }
            bannerQuaternary {
              heading
              image {
                ...WpImageFragment
              }
              text
            }
            employerBrandingTeam {
              businessCards {
                businessCard {
                  fullName
                  position
                  positionTitle
                  text
                  image {
                    ...WpImageFragment
                  }
                  contactList {
                    type
                    link {
                      ariaLabel
                      wpInternal {
                        ... on WpPage {
                          id
                          uri
                        }
                      }
                      external {
                        target
                        url
                      }
                    }
                  }
                }
              }
              backgroundImage {
                ...WpImageFragment
              }
            }
            employerBrandingDescription: mediaServiceDescription {
              heading
              text
            }
            mediaServiceWhatIs {
              heading
              text: description
              image {
                ...WpImageFragment
              }
              bulletsList {
                text
              }
            }
            mediaServiceWhen {
              heading
              text: description
              image {
                ...WpImageFragment
              }
              bulletsList {
                text
              }
              linkButton {
                icon {
                  name
                }
                label
                link {
                  ariaLabel
                  internal: wpInternal {
                    ... on WpPage {
                      uri
                    }
                  }
                  external {
                    target
                    url
                  }
                }
              }
            }
            recruitmentProcess: supportProcess {
              heading
              subTitle
              processSteps {
                heading
                text
              }
            }
            otherServicesBlock {
              subTitle
              heading
              text
              cardList: servicesList {
                heading
                text
                icon {
                  name
                }
                linkButton {
                  label
                  link {
                    ariaLabel
                    internal: wpInternal {
                      ... on WpPage {
                        id
                        uri
                      }
                    }
                    external {
                      target
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    commonComponents: wpPage(slug: { eq: $commonComponentsSlug }) {
      commonComponentsData {
        letsTalkBanner {
          heading
          description
          linkButton {
            label
            icon {
              name
            }
            link {
              ariaLabel
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
              external {
                target
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default OffersMediaServicePage;
