import React from 'react';

import {
  StyledBottomContainer,
  StyledContainerContent,
  StyledHeadingTitle,
  StyledTopContainer,
  StyledWrapper,
} from './company-text-container.styles';

export type CompanyTextContainerType = {
  children?: JSX.Element | JSX.Element[];
  styledTitle?: string;
  isPaddingBottom?: boolean;
  isPaddingTop?: boolean;
};

const CompanyTextContainer = ({
  isPaddingTop = false,
  isPaddingBottom = false,
  styledTitle,
  children,
}: CompanyTextContainerType) => (
  <StyledWrapper>
    <StyledTopContainer isPaddingTop={isPaddingTop} />
    {styledTitle ? (
      <StyledHeadingTitle variant="title2" color="gray100" html={styledTitle} component="h2" />
    ) : null}
    <StyledContainerContent>{children}</StyledContainerContent>
    <StyledBottomContainer isPaddingBottom={isPaddingBottom} />
  </StyledWrapper>
);

export default CompanyTextContainer;
