import styled from 'styled-components';
import { breakpoint } from 'theme';

import Container from 'components/atoms/container';
import Typography from 'components/atoms/typography';

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledTopContainer = styled.div<{ isPaddingTop?: boolean }>`
  ${breakpoint.md} {
    padding-top: ${({ isPaddingTop }) => (isPaddingTop ? '48px' : 0)};
  }
`;

export const StyledBottomContainer = styled.div<{ isPaddingBottom?: boolean }>`
  padding-bottom: ${({ isPaddingBottom }) => (isPaddingBottom ? '12px' : 0)};

  ${breakpoint.md} {
    padding-bottom: ${({ isPaddingBottom }) => (isPaddingBottom ? '48px' : 0)};
  }
`;

export const StyledHeadingTitle = styled(Typography)`
  margin: auto;

  padding-top: ${({ theme }) => theme.gap.sm};
  ${breakpoint.md} {
    max-width: ${({ theme }) => theme.wrapper};
    padding-top: 24px;
    padding-bottom: 24px;
  }
`;

export const StyledContainerContent = styled(Container)`
  margin: auto;

  ${breakpoint.md} {
    max-width: ${({ theme }) => theme.wrapper};
    padding-top: 0;
  }
`;
